import { Row, Col } from 'antd'

import { GroupedAlignment } from './groupedAlignment'
import LazySlider from '../../../../../components/LazySlider'
import { LayerControl } from '../../../../../components/LayerControl'
import ColorEditorButton from '../../../colorsEditor/colorEditorButton'

import { STYLE } from './constants'
import { SHAPES_TYPES } from '../../../constants'

import { useCommonControlsState } from './useCommonControlsState'

export const CommonControls = (props) => {
  const {
    activeObjectType,
    activeObjectProps,
    isActiveSelection,
    canvasActiveObject,
    selectionWithAvatar,
    setActiveObjectModifier,
  } = props

  const {
    hasApi,
    isLayeringAvailable,
    handleBorderWidth,
    handleBorderColor,
    onChangeRadiusStep,
    onChangeBorderWidthStep,
    handleImagePosition,
    handleOpacityChange,
    handleClipPathChange,
  } = useCommonControlsState({
    canvasActiveObject,
    setActiveObjectModifier,
  })

  return (
    <>
      {isActiveSelection && <GroupedAlignment setActiveObjectModifier={setActiveObjectModifier} />}
      {(!isActiveSelection || !selectionWithAvatar) && (
        <>
          {SHAPES_TYPES.includes(activeObjectType) && (
            <>
              <Row align="middle" style={STYLE.marginTop10}>
                <Col span={9}>
                  <h4 style={STYLE.marginBottom0}>Border width</h4>
                </Col>
                <Col span={15} style={STYLE.paddingRight2}>
                  <LazySlider
                    value={activeObjectProps.strokeWidth || 0}
                    linkedElement={activeObjectProps}
                    min={0}
                    max={20}
                    step={1}
                    onChange={handleBorderWidth}
                    onStep={onChangeBorderWidthStep}
                  />
                </Col>
              </Row>
              {activeObjectProps.strokeWidth > 0 && (
                <Row align="middle" style={STYLE.marginTop10}>
                  <Col span={9}>
                    <h4 style={STYLE.marginBottom0}>Border color</h4>
                  </Col>
                  <Col span={15}>
                    <div style={STYLE.colorButton}>
                      <ColorEditorButton
                        color={activeObjectProps.stroke || 'rgba(0, 0, 0, 0)'}
                        onChangeColor={handleBorderColor}
                      />
                    </div>
                  </Col>
                </Row>
              )}
            </>
          )}
          {(activeObjectType === 'rect' ||
            (activeObjectType === 'image' && !activeObjectProps.meta?.listeningAvatar)) && (
            <Row align="middle" style={STYLE.marginTop10}>
              <Col span={9}>
                <h4 style={STYLE.marginBottom0}>Border radius</h4>
              </Col>
              <Col span={15} style={STYLE.paddingRight2}>
                {activeObjectType === 'image' && (
                  <LazySlider
                    value={activeObjectProps.clipPath?.rx}
                    min={0}
                    max={320}
                    step={8}
                    onStep={onChangeRadiusStep}
                    tooltip={{ formatter: (v) => v / 8 }}
                    onChange={handleClipPathChange}
                  />
                )}
                {activeObjectType === 'rect' && (
                  <LazySlider
                    value={activeObjectProps.rx}
                    linkedElement={activeObjectProps}
                    linkedElementKey={['rx', 'ry']}
                    min={0}
                    max={40}
                    step={1}
                    onChange={handleImagePosition}
                  />
                )}
              </Col>
            </Row>
          )}
          {!activeObjectProps.meta?.listeningAvatar && (
            <Row align="middle" style={STYLE.marginTop10}>
              <Col span={9}>
                <h4>Opacity</h4>
              </Col>
              <Col span={15}>
                <LazySlider
                  value={activeObjectProps.opacity}
                  linkedElement={canvasActiveObject}
                  linkedElementKey="opacity"
                  min={0.05}
                  max={1}
                  step={0.01}
                  onChange={handleOpacityChange}
                />
              </Col>
            </Row>
          )}
          {!isActiveSelection &&
            !activeObjectProps.meta?.listeningAvatar &&
            hasApi &&
            ['image', 'frame', 'video'].includes(activeObjectType) && (
              <Row align="middle" style={STYLE.marginTop10}>
                <Col span={9}>
                  <h4>API Id</h4>
                </Col>
                <Col span={15}>{activeObjectProps.id}</Col>
              </Row>
            )}
          {!activeObjectProps.bg && isLayeringAvailable && (
            <Row align="middle" className="layer-control" style={STYLE.marginTop10}>
              <Col span={6}>
                <h4 style={STYLE.margin0}>Layering</h4>
              </Col>
              <LayerControl setActiveObjectModifier={setActiveObjectModifier} />
            </Row>
          )}
        </>
      )}
    </>
  )
}
