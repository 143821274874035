import { fabric } from 'fabric'

import { useStore } from '../../../../../store'

import { isCanvasObjectLayeringAvailable } from '../../../../../utils/canvas/canvasLayering'

export const useCommonControlsState = (props) => {
  const { canvasActiveObject, setActiveObjectModifier } = props

  const hasApi = useStore((stores) => stores.authStore.user.account.hasApi)

  const isLayeringAvailable = isCanvasObjectLayeringAvailable(canvasActiveObject)

  const handleOpacityChange = (v) => {
    setActiveObjectModifier({ change: 'opacity', value: v })
  }

  const handleImagePosition = (v) =>
    setActiveObjectModifier({
      change: { rx: v, ry: v },
    })

  // border radius
  const handleClipPathChange = (value) => setActiveObjectModifier({ change: 'clipPath', value })

  const onChangeRadiusStep = (v) => {
    canvasActiveObject.set(
      'clipPath',
      new fabric.Rect({
        width: canvasActiveObject.width,
        height: canvasActiveObject.height,
        rx: v,
        ry: v,
        left: -canvasActiveObject.width / 2,
        top: -canvasActiveObject.height / 2,
      }),
    )
    canvasActiveObject.canvas.renderAll()
  }

  const onChangeBorderWidthStep = (v) => {
    canvasActiveObject.set({ strokeWidth: v, strokeUniform: true })
    canvasActiveObject.canvas.renderAll()
  }

  const handleBorderWidth = (v) => {
    const change = { strokeWidth: v }
    //for shapes added earlier without strokeUniform property
    if (!canvasActiveObject.strokeUniform) change.strokeUniform = true
    setActiveObjectModifier({
      change,
    })
  }

  const handleBorderColor = (color) => {
    const change = { stroke: color }
    //for shapes added earlier without strokeUniform property
    if (!canvasActiveObject.strokeUniform) change.strokeUniform = true
    setActiveObjectModifier({ change })
  }

  return {
    hasApi,
    isLayeringAvailable,
    handleBorderWidth,
    handleBorderColor,
    onChangeRadiusStep,
    onChangeBorderWidthStep,
    handleOpacityChange,
    handleImagePosition,
    handleClipPathChange,
  }
}
