import { memo, useMemo } from 'react'
import { Space, Button, Tooltip, Dropdown, Popover, Input } from 'antd'

import { PhonemeModal } from './PhonemeModal/phonemeModal'
import configService from '../../../../../../utils/config'

import Icon from '../../../../../../components/Icon'

import {
  PHONEME_ICON,
  MARKER_ICON,
  PAUSE_ICON,
  pause_menu,
  phoneme_menu,
  SPECIAL_CHARS_PHONEME_REGEX,
  MARK_EMOJI,
} from '../constants'

export const ToolBar = memo((props) => {
  const {
    voice,
    insertPause,
    data,
    insertMarker,
    onClickPhoneme,
    isOpenPhonemeMenu,
    handleClickPhonemeMenu,
    isOpenPhonemeInput,
    phonemeButtonRef,
    phonemeMenuRef,
    phonemeInputRef,
    phonemeInputWrapperRef,
    onPhonemeChange,
    handleClosePhonemeInput,
    editingPhonemeText,
    wordForDictionary,
    isOpenPhonemeModal,
    setWordForDictionary,
    setIsOpenPhonemeModal,
    audioCache,
    resetAudio,
    languages,
    isMarkInsertAllowed,
  } = props

  const { hideElaiMentions } = configService.get().features

  const onToolbarContextMenu = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const phonemeButton = (
    <Tooltip
      title={
        <>
          Change words pronunciation. Rewrite acronyms (ASAP -&gt; eigh-sap), use different letters or break words into
          syllables.{' '}
          {hideElaiMentions ? null : (
            <>
              Read more{' '}
              <a
                target="_blank"
                href="https://elai.freshdesk.com/support/solutions/articles/73000615467-phoneme-guide"
                rel="noreferrer"
              >
                here
              </a>
            </>
          )}
        </>
      }
      overlayStyle={{ visibility: isOpenPhonemeMenu || isOpenPhonemeInput ? 'hidden' : 'visible' }}
      placement="top"
      mouseEnterDelay={0.6}
    >
      <Popover
        open={isOpenPhonemeInput}
        arrow={false}
        overlayClassName="phoneme-popover"
        trigger={['click']}
        content={
          <Tooltip
            open={isOpenPhonemeInput && SPECIAL_CHARS_PHONEME_REGEX.test(editingPhonemeText)}
            title="Please remove special characters, uppercase letters, spaces and numbers from pronunciation"
          >
            <div ref={phonemeInputWrapperRef} className="phoneme-input-wrapper">
              <Input
                ref={phonemeInputRef}
                value={editingPhonemeText}
                className="phoneme-input"
                placeholder="Pronunciation"
                prefix={<span>🎧</span>}
                status={SPECIAL_CHARS_PHONEME_REGEX.test(editingPhonemeText) ? 'error' : null}
                onChange={onPhonemeChange}
                onPressEnter={handleClosePhonemeInput}
              />
              <Button
                className="btn-close"
                icon={<Icon name="close_simple" />}
                shape="circle"
                size="small"
                onClick={handleClosePhonemeInput}
              ></Button>
            </div>
          </Tooltip>
        }
      >
        <Dropdown
          open={isOpenPhonemeMenu}
          menu={{ items: phoneme_menu, onClick: handleClickPhonemeMenu }}
          trigger={['click']}
          overlayClassName="phoneme-menu"
          placement="top"
          dropdownRender={(menu) => <div ref={phonemeMenuRef}>{menu}</div>}
        >
          <Button ref={phonemeButtonRef} type="text" onClick={onClickPhoneme}>
            <img src={PHONEME_ICON} className="toolbarIcon" />
            Phoneme
          </Button>
        </Dropdown>
      </Popover>
    </Tooltip>
  )

  // memoize buttons
  const pauseButton = useMemo(
    () => (
      <Dropdown menu={{ items: pause_menu, onClick: insertPause }} placement="bottom" trigger={['click']}>
        <Button type="text">
          <img src={PAUSE_ICON} className="toolbarIcon" /> Pause
        </Button>
      </Dropdown>
    ),
    [insertPause],
  )

  const markButton = useMemo(
    () => (
      <Tooltip
        title={
          isMarkInsertAllowed
            ? 'You can make objects appear and disappear when avatar say specific words. Add a mark and choose it in your object custom animation settings.'
            : `Maximum marks limit reached (${MARK_EMOJI.length} marks allowed).`
        }
        placement="top"
        mouseEnterDelay={0.6}
      >
        <Button type="text" onClick={insertMarker} disabled={!isMarkInsertAllowed}>
          <img src={MARKER_ICON} className="toolbarIcon" />
          Mark
        </Button>
      </Tooltip>
    ),
    [isMarkInsertAllowed, insertMarker],
  )

  return (
    <>
      <Space.Compact id="toolbar" className="toolbarButton" onContextMenu={onToolbarContextMenu}>
        {phonemeButton}
        {pauseButton}
        {markButton}
      </Space.Compact>
      <PhonemeModal
        voice={voice}
        data={data}
        wordForDictionary={wordForDictionary}
        setWordForDictionary={setWordForDictionary}
        isOpenPhonemeModal={isOpenPhonemeModal}
        setIsOpenPhonemeModal={setIsOpenPhonemeModal}
        audioCache={audioCache}
        resetAudio={resetAudio}
        languages={languages}
      />
    </>
  )
})
