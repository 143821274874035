import { Select } from 'antd'

import Icon from '../../../../components/Icon'
import { easingsList } from '../../slide/canvas/animations/easings'
import LazySlider from '../../../../components/LazySlider'

export const getAnimationTimeSelectValue = (animation, type) => {
  const markerType = type === 'Enter' ? 'startMarker' : 'endMarker'
  const timeType = type === 'Enter' ? 'startTime' : 'endTime'
  const sceneType = type === 'Enter' ? 'startScene' : 'endScene'
  return !animation?.[sceneType] && !animation?.[markerType]
    ? {
        value: animation?.[timeType] || 0,
        label: (
          <span>
            <Icon name="clock" className="animation-clock" />
            {animation?.[timeType] || 0}s
          </span>
        ),
      }
    : animation?.[sceneType] || animation?.[markerType]
}

export const buildEasing = () => {
  return easingsList.map((easing) => (
    <Select.Option key={easing.name} value={easing.name}>
      <Icon name={easing.icon} />
      {easing.label}
    </Select.Option>
  ))
}

const buildInitialMarks = () => {
  const marks = {}
  for (let i = 0.1; i < 1; i += 0.1) {
    marks[i.toFixed(1)] = {
      label: <></>,
    }
  }
  return marks
}

export const durationMarks = buildInitialMarks()

const style = {
  top3: { top: 3 },
  railStyle: { backgroundColor: 'rgba(189, 189, 189, 0.1)', height: 8, borderRadius: 8 },
  trackStyle: { backgroundColor: 'rgba(72, 104, 255, 0.2)', height: 8, borderRadius: 8 },
}

export const DurationSlider = ({ type, value, defaultValue, min = 0, ...props }) => {
  return (
    <LazySlider
      className="animation-duration-slider"
      min={min}
      max={6}
      marks={durationMarks}
      step={0.5}
      tooltip={{ formatter: (v) => `${v}s` }}
      defaultValue={defaultValue}
      value={typeof value === 'number' ? value : defaultValue}
      railStyle={style.railStyle}
      trackStyle={style.trackStyle}
      handleStyle={style.top3}
      {...props}
    />
  )
}
