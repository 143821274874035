import { Link } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import Scrollbars from 'react-custom-scrollbars'
import { Row, Col, Modal, Radio } from 'antd'

import Icon from '../../../../components/Icon'
import { TemplatesColumn } from './templatesColumn'
import PageHeader from '../../../../components/PageHeader/pageHeader'

import { useStore } from '../../../../store'
import { request } from '../../../../utils/api'

import { style } from './constants'

import { applyLogo, applyCorporateColors } from '../../../video/colorsEditor/helpers'

import { uniqueId } from '../../../../utils/helpers'
import { isTemplateApplicable } from '../../../../utils/videos'
import useScrollToBottom from '../../../../hooks/useScrollToBottom'
import { Brandkit } from './brandkit'

const templatesPageSize = 8

export const processExtraLayouts = (extraLayouts) => {
  if (!extraLayouts?.length) return
  // NOTE: it requires to have unique id for each layout (but not for 100% sure it's a right place)
  extraLayouts.forEach((layout) => (layout.id = layout.id ?? uniqueId()))
}

export const TemplatesModal = (props) => {
  const {
    video,
    updateVideo,
    setIsSwitching,
    generateModalFooter,
    isOpenTemplatesModal,
    setIsOpenTemplatesModal,
    validateAndGenerateVideo,
    updateCanvasesInStoryMode,
    setIsEditorBusy,
  } = props

  const storeTemplates = useStore((stores) => stores.videosStore.templates)
  const {
    user: { account },
    refreshSession,
  } = useStore((stores) => stores.authStore)

  const [templatesFilter, setTemplatesFilter] = useState()
  const [templates, setTemplates] = useState()
  const [openedTemplate, setOpenedTemplate] = useState(false)
  const [isChangingTemplate, setIsChangingTemplate] = useState(false)
  const [templatesToShow, setTemplatesToShow] = useState([])
  const [isBrandkitSelected, setIsBrandkitSelected] = useState(false)

  const ttvTemplates = useMemo(() => {
    return storeTemplates
      ?.filter(isTemplateApplicable)
      .filter(
        (t) =>
          !t.template.tags?.some((tag) =>
            ['Interactive videos', 'Social media', 'Advertisement', 'E-commerce'].includes(tag),
          ),
      )
  }, [storeTemplates])

  const containsPrivateTemplates = ttvTemplates?.some((t) => t.template.private)

  const applyColor = (updatedVideo) => {
    if (applyCorporateColors(updatedVideo, account.corporateColors, undefined, true))
      updateVideo({ slides: video.slides })
  }

  const changeTemplate = async (createSlides = false) => {
    setIsChangingTemplate(true)
    setIsEditorBusy(true)

    const updatedVideo = await request({
      method: 'POST',
      url: `story/change-template/${video._id}`,
      data: { templateId: openedTemplate._id },
    })
    if (!updatedVideo) return setIsChangingTemplate(false)

    if (isBrandkitSelected) {
      await applyLogo(updatedVideo, account.logo)
      applyColor(updatedVideo)
    }

    processExtraLayouts(updatedVideo.extraLayouts)
    updateVideo(updatedVideo, { skipAutoSave: true })
    await updateCanvasesInStoryMode(updatedVideo.slides)

    setIsChangingTemplate(false)
    setIsEditorBusy(false)
    setIsOpenTemplatesModal(false)
    setOpenedTemplate(false)
    setIsSwitching(false)
    if (createSlides === true) {
      // wait while video state will be completely updated
      setTimeout(() => validateAndGenerateVideo(), 1000)
    }
  }

  const updateTemplatesToShow = (count) => {
    if (!templates?.length) return setTemplatesToShow([])
    setTemplatesToShow(templates.slice(0, count))
  }

  useEffect(() => {
    updateTemplatesToShow(templatesPageSize)
  }, [templates, templatesFilter])

  const handleScrollToBottom = () => {
    const newCount = templatesToShow.length + templatesPageSize
    updateTemplatesToShow(newCount)
  }

  const { scrollRef, saveScrollPosition } = useScrollToBottom(
    handleScrollToBottom,
    !!openedTemplate,
    (el) => el?.parentNode,
  )

  useEffect(() => {
    setTemplatesFilter(containsPrivateTemplates ? 'private' : 'all')
    setTemplates(
      containsPrivateTemplates ? ttvTemplates.filter((t) => t.template.private && t.template.enabled) : ttvTemplates,
    )
  }, [storeTemplates])

  useEffect(() => {
    if (!templates?.length) return
    setTemplatesToShow(templates.slice(0, templatesPageSize))
  }, [templates])

  useEffect(() => {
    if (!isOpenTemplatesModal) updateTemplatesToShow(templatesPageSize)
  }, [isOpenTemplatesModal])

  const onChangeTemplatesFilter = (e) => {
    const filter = e.target.value
    setTemplatesFilter(filter)
    setTemplates(() => {
      if (filter === 'all') return ttvTemplates
      else if (filter === 'private') return ttvTemplates.filter((t) => t.template.private && t.template.enabled)
      else if (filter === '16_9') return ttvTemplates.filter((t) => !t.data?.format || t.data.format === '16_9')
      else if (filter.format !== 'all') return ttvTemplates.filter((t) => t.data?.format === filter)
    })
  }

  const onCancel = () => {
    setIsSwitching(false)
    setIsOpenTemplatesModal(false)
    setOpenedTemplate(false)
  }

  const openTemplate = (template) => {
    saveScrollPosition()
    setOpenedTemplate(template)
  }

  const modalFooter = generateModalFooter(changeTemplate, openedTemplate, isChangingTemplate)
  const onBack = openedTemplate ? () => setOpenedTemplate(null) : false

  const handleCreateTemplate = () => localStorage.setItem('isNewTemplateModalOpen', 'open')

  // it's required for correct work of ref for scroll to bottom
  if (!isOpenTemplatesModal) return null

  return (
    <Modal
      centered
      open={isOpenTemplatesModal}
      width={700}
      destroyOnClose
      className="templates-modal"
      cancelButtonProps={style.cancelButtonProps}
      confirmLoading={isChangingTemplate}
      footer={modalFooter}
      onCancel={onCancel}
    >
      <PageHeader style={style.pageHeader} title="Choose a template" onBack={onBack} />
      {openedTemplate ? (
        <>
          <Brandkit
            account={account}
            refreshSession={refreshSession}
            isBrandkitSelected={isBrandkitSelected}
            setIsBrandkitSelected={setIsBrandkitSelected}
          />
          <Scrollbars className="template-scrollbar scrollbar" style={style.height319}>
            <Row gutter={[16, 16]}>
              {openedTemplate.slides.map((s) => (
                <Col
                  xs={openedTemplate?.data?.format === '9_16' ? 6 : openedTemplate?.data?.format === '1_1' ? 6 : 12}
                  sm={openedTemplate?.data?.format === '9_16' ? 4 : openedTemplate?.data?.format === '1_1' ? 6 : 12}
                  md={openedTemplate?.data?.format === '9_16' ? 3 : openedTemplate?.data?.format === '1_1' ? 6 : 8}
                  lg={openedTemplate?.data?.format === '9_16' ? 3 : openedTemplate?.data?.format === '1_1' ? 6 : 8}
                  key={s.id}
                >
                  <img
                    src={s.screenshot}
                    className={`template-media ${
                      openedTemplate?.data?.format === '9_16' || openedTemplate?.data?.format === '1_1'
                        ? 'vertical'
                        : ''
                    }`}
                  />
                </Col>
              ))}
            </Row>
          </Scrollbars>
        </>
      ) : (
        <>
          <Radio.Group className="format-radio-btns" value={templatesFilter} onChange={onChangeTemplatesFilter}>
            <Radio.Button value="all" className={`format-radio-btn ${templatesFilter === 'all' ? 'active' : ''}`}>
              <Icon name="page_collection" />
              <span>All</span>
            </Radio.Button>
            {containsPrivateTemplates && (
              <Radio.Button
                value="private"
                className={`format-radio-btn ${templatesFilter === 'private' ? 'active' : ''}`}
              >
                <Icon name="multiple_image" />
                <span>My templates</span>
              </Radio.Button>
            )}
            <Radio.Button value="16_9" className={`format-radio-btn ${templatesFilter === '16_9' ? 'active' : ''}`}>
              <Icon name="monitor" />
              <span>Horizontal</span>
            </Radio.Button>
            <Radio.Button value="9_16" className={`format-radio-btn ${templatesFilter === '9_16' ? 'active' : ''}`}>
              <Icon name="mobile" />
              <span>Vertical</span>
            </Radio.Button>
          </Radio.Group>
          <Scrollbars className="template-scrollbar scrollbar" style={{ height: containsPrivateTemplates ? 267 : 319 }}>
            <Row gutter={[16, 16]} ref={scrollRef}>
              <Col key="create-template" style={style.flex} xs={12} sm={12} md={8}>
                <Link to="/templates" target="_blank" style={style.link} onClick={handleCreateTemplate}>
                  <div className="create-template-option">
                    <Icon name="add_template" />
                    <span>Create {templatesFilter === 'private' ? 'new' : 'own'} template</span>
                  </div>
                </Link>
              </Col>
              {templatesToShow?.map((v, index) => (
                <TemplatesColumn key={v._id} video={v} index={index} setOpenedTemplate={openTemplate} />
              ))}
            </Row>
          </Scrollbars>
        </>
      )}
    </Modal>
  )
}
