export const FLAGS_URL = 'https://d3u63mhbhkevz8.cloudfront.net/flags/'

export const FILE_TYPES = ['audio', 'video']

export const DEFAULT_SILENCE_DURATION = 10

export const PLAYER_STATUSES = {
  idle: 'idle',
}

export const STYLE = {
  marginTop16: { marginTop: 16 },
  marginRight16: { marginRight: 16 },
  displayInline: { display: 'inline' },
  marginBottom16: { marginBottom: 16 },
  positionRel: { position: 'relative' },
  errorColor: { color: '#cf5d60' },
}

export const formatVoiceTags = (voice) => {
  if (voice.playedTags?.length) voice.playedTags = voice.playedTags.map((t) => ({ ...t, name: t.name.toLowerCase() }))
  if (voice.tags?.length) voice.tags = voice.tags.map((t) => t.toLowerCase())
  if (voice.styleList?.length)
    voice.styleList = voice.styleList.map((style) => style.toLowerCase()).sort((a, b) => (a < b ? -1 : a === b ? 0 : 1))
  return voice
}
