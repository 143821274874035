import { osMap } from './constants'
import { notification } from 'antd'

const timeSince = (date) => {
  var seconds = Math.floor((new Date() - date) / 1000)

  var interval = seconds / 31536000

  if (interval > 1) {
    return Math.floor(interval) + ' years'
  }
  interval = seconds / 2592000
  if (interval > 1) {
    return Math.floor(interval) + ' months'
  }
  interval = seconds / 86400
  if (interval > 1) {
    return Math.floor(interval) + ' days'
  }
  interval = seconds / 3600
  if (interval > 1) {
    return Math.floor(interval) + ' hours'
  }
  interval = seconds / 60
  if (interval > 1) {
    return Math.floor(interval) + ' minutes'
  }
  return Math.floor(seconds) + ' seconds'
}

const promisify = (f) => {
  return function (...args) {
    return new Promise((resolve, reject) => {
      function callback(err, result) {
        if (err) {
          reject(err)
        } else {
          resolve(result)
        }
      }
      args.push(callback)
      f.call(this, ...args)
    })
  }
}

const uniqueId = () => Math.floor(Math.random() * Date.now())

const isEmptyString = (str) => typeof str === 'string' && !str.trim().length

const capitalizeFirstLetter = (string) => {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : ''
}

const debounce = (func, delay, withCounter = false) => {
  let timeoutId
  let counter = 0
  const debounced = (...args) => {
    clearTimeout(timeoutId)
    counter++
    timeoutId = setTimeout(() => {
      if (withCounter) args.push(counter)
      func.apply(this, args)
      counter = 0
    }, delay)
  }
  debounced.cancel = () => {
    clearTimeout(timeoutId)
  }
  return debounced
}

const queueMicrotask = window.queueMicrotask || ((fn) => Promise.resolve().then(fn))

const requestIdleCallback = window.requestIdleCallback || ((fn) => setTimeout(fn, 0))

const escapeRegExp = (string) => string.replace(/[-[\]/{}()*+?.\\^$|]/g, '\\$&')

const clone = (obj) => {
  try {
    // by default try to use structuredClone,
    // but it can throw error if object contains references to DOM elements
    if (window.structuredClone) {
      return window.structuredClone(obj)
    }
  } catch {
    // ignore
  }
  // otherwise use JSON representation
  return JSON.parse(JSON.stringify(obj))
}

// eslint-disable-next-line no-promise-executor-return
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

const getRandomNumber = (max) => Math.floor(Math.random() * max)

const backoffMs = (attempt) => (attempt + 1) * 2000

const isSignedUrl = (url) => /\/\/elai-|x-amz-|cloudfront\.net|blob\.core\.windows\.net/i.test(url)

const getOS = () => {
  if (navigator.userAgent.indexOf('Win') !== -1) return osMap.WINDOWS
  if (navigator.userAgent.indexOf('Mac') !== -1) return osMap.MAC
  if (navigator.userAgent.indexOf('Linux') !== -1) return osMap.LINUX
}

const elaiNotification = {
  antdNotification: notification,
  success: notification.success,
  error: notification.error,
  info: notification.info,
  warning: notification.warning,
  open: notification.open,
  destroy: async (key) => {
    notification.destroy(key)
    // because of some weird old bug when destroying a notification doesn't synced with internal state
    return new Promise((res) => setTimeout(res, 100))
  },
}

// duration should be a number of minutes
const formatMinutes = (minutesDuration, fullText = false) => {
  if (!minutesDuration) return ''

  const hours = Math.floor(minutesDuration / 60)
  const minutes = Math.round(minutesDuration - hours * 60)

  const minutesDesc = fullText ? ` minute${minutes > 1 ? 's' : ''}` : 'm'
  const hoursDesc = fullText ? ` hour${hours > 1 ? 's' : ''}` : 'h'

  const hoursText = hours > 0 ? `${hours}${hoursDesc}` : ''
  const minutesText = minutes > 0 ? `${minutes}${minutesDesc}` : ''

  return minutesDuration < 60 ? `${minutesText}`.trim() : `${hoursText} ${minutesText}`.trim()
}

const promiseAllWithLimit = async (queue, fn, limit) => {
  let index = 0
  let errorBreak = false
  const results = []

  const execThread = async () => {
    while (index < queue.length) {
      if (errorBreak) return
      const curIndex = index++
      try {
        results[curIndex] = await fn(queue[curIndex]).catch((err) => err)
      } catch (err) {
        errorBreak = err
        break
      }
    }
  }

  await Promise.all(Array.from({ length: limit }, execThread))
  return errorBreak ? Promise.reject(errorBreak) : results
}

const removeDuplicatesFromArray = (items) => {
  return items.filter((item, index, self) => self.findIndex((duplicate) => duplicate === item) === index)
}

const iterateFabricObjects = ({
  objects,
  shouldProcessObject = () => true,
  processObject = (obj) => obj,
  eachObjectHandler = null,
}) => {
  objects.forEach((obj) => {
    if (obj.type === 'group') {
      iterateFabricObjects({
        objects: obj._objects ?? obj.objects,
        shouldProcessObject,
        processObject,
        eachObjectHandler,
      })
    }
    if (shouldProcessObject(obj)) processObject(obj)
    eachObjectHandler?.(obj)
  })
}

/**
 * Pulls out texts from canvasClonedObject
 * @param content {String}
 * @returns {String}
 */
const restoreTextFromClonedCanvasObject = (content) => {
  if (!content.startsWith('{')) return content
  try {
    const { clonedCanvasObject } = JSON.parse(content)
    if (!clonedCanvasObject) return content
    const { text, type, objects } = clonedCanvasObject
    if (text) return text
    if (type !== 'activeSelection') return content
    return objects
      .map((object) => object.text)
      .filter(Boolean)
      .join(' ')
  } catch {
    return content
  }
}

const restoreTextFromJson = (text) => {
  const result = restoreTextFromClonedCanvasObject(text)
  if (result.startsWith('{')) return ''
  return result
}

const deepMerge = (obj1, obj2) => {
  const result = { ...obj1 }
  for (const key in obj2) {
    if (obj2[key] && typeof obj2[key] === 'object' && !Array.isArray(obj2[key])) {
      result[key] = deepMerge(result[key] || {}, obj2[key])
    } else {
      result[key] = obj2[key]
    }
  }
  return result
}

const isSvgWithNestedBase64 = async (file) =>
  new Promise((resolve) => {
    if (file?.name && !file.name.endsWith('.svg')) resolve(false)
    const reader = new FileReader()
    reader.onload = (event) => {
      const fileContent = event.target.result
      const images = fileContent.match(/<image[^>]*xlink:href="([^"]*)"[^>]*>/g)
      resolve(images?.some((image) => image.includes('data:image')))
    }
    reader.readAsText(file)
  }).catch(() => false)

export {
  timeSince,
  promisify,
  uniqueId,
  isEmptyString,
  capitalizeFirstLetter,
  debounce,
  queueMicrotask,
  requestIdleCallback,
  escapeRegExp,
  clone,
  sleep,
  getRandomNumber,
  backoffMs,
  isSignedUrl,
  getOS,
  formatMinutes,
  elaiNotification,
  promiseAllWithLimit,
  removeDuplicatesFromArray,
  iterateFabricObjects,
  restoreTextFromJson,
  restoreTextFromClonedCanvasObject,
  deepMerge,
  isSvgWithNestedBase64,
}
