import { Drawer } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import { useState, useEffect, useCallback } from 'react'
import CorporateColors from './corporateColors'
import VideoColors from './videoColors'
import DefaultColors from './defaultColors'
import ChangeAll from './changeAll'
import { useStore } from '../../../store'
import './colorsEditor.less'
import { normalizeColor } from './helpers'

const ColorsEditor = ({
  video,
  onChangeColor,
  onVideoColorsChange,
  isAllowedTransparent,
  setActiveTab,
  setAssetActiveTab,
}) => {
  const colorEditorStore = useStore((stores) => stores.colorEditorStore)
  const { colorEditor, setVisible: setColorPickerVisible } = colorEditorStore
  const [currentColor, setCurrentColor] = useState()
  const [changesCounter, setChangesCounter] = useState(0)

  useEffect(() => {
    if (!colorEditor.visible) return
    if (document.body.scrollHeight <= document.body.clientHeight) document.body.classList.add('drawer')
    setCurrentColor(colorEditor.color)
  }, [colorEditor.visible])

  useEffect(() => {
    if (colorEditor.visible && colorEditor.color) setCurrentColor(colorEditor.color)
  }, [colorEditor.color])

  const onChangeColorCallback = useCallback(
    (selectedColor) => {
      if (currentColor !== selectedColor) {
        const color = normalizeColor(selectedColor)
        setCurrentColor(color)
        colorEditor.onChange(color)
        onChangeColor()
      }
    },
    [currentColor, colorEditor.onChange],
  )

  return (
    <Drawer
      placement="right"
      width="100%"
      autoFocus={false}
      closeIcon={<ArrowLeftOutlined />}
      headerStyle={{ padding: '15px 20px 0', borderBottom: 0 }}
      bodyStyle={{ padding: '0 20px 0 24px' }}
      getContainer={false}
      onClose={() => colorEditorStore.setVisible(false)}
      open={colorEditor.visible}
      afterOpenChange={() => document.body.classList.remove('drawer')}
    >
      <CorporateColors
        color={colorEditor.color}
        onChangeColor={onChangeColorCallback}
        setActiveTab={setActiveTab}
        setAssetActiveTab={setAssetActiveTab}
        setColorPickerVisible={setColorPickerVisible}
      />
      <VideoColors
        video={video}
        visible={colorEditor.visible}
        onVideoColorsChange={onVideoColorsChange}
        color={colorEditor.color}
        onChangeColor={onChangeColorCallback}
        changesCounter={changesCounter}
        isAllowedTransparent={isAllowedTransparent}
      />
      <DefaultColors color={colorEditor.color} onChangeColor={onChangeColorCallback} />
      <ChangeAll
        color={colorEditor.color}
        visible={colorEditor.visible}
        video={video}
        onVideoColorsChange={onVideoColorsChange}
        changesCounter={changesCounter}
        setChangesCounter={setChangesCounter}
      />
    </Drawer>
  )
}

export default ColorsEditor
