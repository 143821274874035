export const OPTIONS = [
  {
    label: <span>Assets</span>,
    value: 'assets',
    title: 'Assets',
    placement: 'bottomLeft',
  },
  {
    label: 'Brandbook',
    value: 'brandbook',
    placement: 'bottomRight',
  },
]

export const ASSETS_SORT = {
  NAME_ASCEND: 'NAME_ASCEND',
  NAME_DESCEND: 'NAME_DESCEND',
  UPDATED_AT_ASCEND: 'UPDATED_AT_ASCEND',
  UPDATED_AT_DESCEND: 'UPDATED_AT_DESCEND',
}

export const ASSETS_TYPES = {
  ALL: 'all',
  IMAGES: 'images',
  VIDEOS: 'videos',
  VECTORS: 'vectors',
  RECORDINGS: 'recordings',
}

export const assetsSorterMenuItems = [
  { label: 'Last assets', key: ASSETS_SORT.UPDATED_AT_DESCEND },
  { label: 'From old to new', key: ASSETS_SORT.UPDATED_AT_ASCEND },
  { label: 'From A to Z', key: ASSETS_SORT.NAME_ASCEND },
  { label: 'From Z to A', key: ASSETS_SORT.NAME_DESCEND },
]

export const assetsTypesMenuItems = [
  { label: 'All', key: ASSETS_TYPES.ALL },
  { label: 'Images', key: ASSETS_TYPES.IMAGES },
  { label: 'Videos', key: ASSETS_TYPES.VIDEOS },
  { label: 'Vectors', key: ASSETS_TYPES.VECTORS },
  { label: 'Recordings', key: ASSETS_TYPES.RECORDINGS },
]
