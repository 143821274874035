import React, { useState, useCallback } from 'react'
import { Dropdown, Button } from 'antd'

import Icon from '../../../../../../components/Icon'

import { ASSETS_SORT, assetsSorterMenuItems } from '../../constants'

export const FileUploaderSorter = ({ value, onChange }) => {
  const [open, setOpen] = useState(false)

  const handleChange = useCallback(
    ({ key }) => {
      if (key !== value) {
        onChange(key)
      }
      setOpen(false)
    },
    [value, onChange],
  )

  return (
    <Dropdown
      key="sorter-dropdown"
      menu={{
        items: assetsSorterMenuItems,
        selectedKeys: [value || ASSETS_SORT.UPDATED_AT_DESCEND],
        onClick: handleChange,
      }}
      trigger={['click']}
      open={open}
      onOpenChange={setOpen}
    >
      <Button type={open ? 'primary' : 'default'} icon={<Icon name="sort" />} className="uploads-media-header-button" />
    </Dropdown>
  )
}
