import { Typography } from 'antd'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { debounce } from 'throttle-debounce'

import Icon from '../../../../../components/Icon'

import {
  MARKER_ICON,
  PAUSE_EMOJI,
  PAUSE_ICON,
  PHONEME_ICON,
  formatSpeech,
  HISTORY_SIZE,
  MULTIPLE_PHONEME_REGEX,
  SINGLE_PHONEME_REGEX,
  SPECIAL_CHARS_PHONEME_REGEX,
  MARK_EMOJI,
  TEMPORARY_MARKER_REPLACER,
} from './constants'

import { useStore } from '../../../../../store'
import { request } from '../../../../../utils/api'
import { findFirstDifferenceIndex } from './helpers'
import { restoreTextFromJson } from '../../../../../utils/helpers'
import { track } from '../../../../../utils/analytics'
import { getDefaultVoice } from '../../../../../utils/videos'
import { resetTimeOfObjectsWithMarkers } from '../../../../../utils/canvas/canvas'
import useClickOutside from '../../../../../hooks/useClickOutside'
import { os } from '../../../constants'
import { osMap } from '../../../../../utils/constants'
import { useSlideDuration } from '../../../../../hooks/useSlideDuration'
import { useUpdateSpeechTextarea } from './useUpdateSpeechTextarea'

const { Text } = Typography

const phonemeValuePosition = 13

let slideId = ''

export const useSpeechEditorState = (props) => {
  const { data, updateSlide, undoLastChanges } = props
  const speechContextMenuRef = useRef()
  const textareaRef = useRef(null)
  const phonemeButtonRef = useRef(null)
  const phonemeMenuRef = useRef(null)
  const phonemeInputRef = useRef(null)
  const phonemeInputWrapperRef = useRef(null)

  const [wordForDictionary, setWordForDictionary] = useState()
  const [isFocused, setIsFocused] = useState(false)
  const [history, setHistory] = useState([])
  const [isOpenPhonemeModal, setIsOpenPhonemeModal] = useState(false)
  const [isOpenSpeechContextMenu, setIsOpenSpeechContextMenu] = useState(false)
  const [isOpenPhonemeMenu, setIsOpenPhonemeMenu] = useState(false)
  const [isOpenPhonemeInput, setIsOpenPhonemeInput] = useState(false)
  const [editingPhonemeText, setEditingPhonemeText] = useState('')
  const [textAreaCursorPosition, setTextAreaCursorPosition] = useState(null)
  const [isSpeechEditorDisabled, setIsSpeechEditorDisabled] = useState(false)
  const voices = useStore((stores) => stores.voicesStore.voices)
  const { status: accountStatus, plan: accountPlan } = useStore((stores) => stores.authStore.user.account)

  // Initialize with first loaded slide speech
  const [speech, setSpeech] = useState(data.speech)

  const { getApproxDuration } = useSlideDuration({ slide: data })
  const { handleSpeechChanges, replaceTagsToEmoji, replaceEmojiToTags, getMarkValues, marksTotal } =
    useUpdateSpeechTextarea(textareaRef, setSpeech)

  // If slide was changed update speech
  useEffect(() => {
    if (slideId !== data.id) {
      // reset history as it can copy prev slide speech into new one
      setHistory([data.speech])
      slideId = data.id
    }
  }, [data.id])

  // if speech changed outside of editor
  useEffect(() => {
    setSpeech(replaceTagsToEmoji(data.speech ?? ''))
  }, [data.speech])

  const restoreCursorPosition = () => {
    if (!textAreaCursorPosition) return
    const { startPosition, endPosition } = textAreaCursorPosition
    if (!startPosition || !endPosition) return
    textareaRef.current.selectionStart = startPosition
    textareaRef.current.selectionEnd = startPosition
    // TODO position is not correctly working with cut/pasting markers
    // textareaRef.current.selectionEnd = endPosition
    setTextAreaCursorPosition(null)
  }

  // Handle user typing
  const onSpeechChange = (event) => {
    const { value: newSpeech } = event.target
    // Check if speech has actually changed
    if (speech === newSpeech) return

    handleSpeechChanges()
  }

  // update slide speech
  const saveSpeechChanges = useCallback(async () => {
    // Update history
    if (history[history.length - 1] !== speech) {
      const newHistory = [...history, speech]
      if (newHistory.length > HISTORY_SIZE) newHistory.unshift()
      setHistory(newHistory)
    }
    const replacedText = replaceEmojiToTags(speech)

    // if there is no changes do nothing
    if (replacedText === data.speech) {
      // TODO: figure out how to fix more elegant
      return
    }

    // remove extra whitespaces
    const formatedSpeech = formatSpeech(replacedText)
    const { objects, requestUpdateCanvas } = resetTimeOfObjectsWithMarkers(data.canvas.objects, getMarkValues())

    updateSlide({
      speech: formatedSpeech,
      duration: null,
      approxDuration: getApproxDuration({ ...data, speech: formatedSpeech }),
      status: 'edited',
      language: data.language,
      canvas: { ...data.canvas, objects },
      updateCanvas: requestUpdateCanvas,
    })
  }, [speech, data.speech, data.language, data.canvas])

  // detecting speech language
  const detectAndSetLanguage = async (value) => {
    if (value?.charAt(0) === data.speech?.charAt(0)) {
      return
    }
    const { name: languageName } = await request({
      method: 'post',
      url: '/videos/detectLanguage',
      data: { speech: value },
    })
    if (!languageName || data.language === languageName) return
    const voice = voices.find((language) => language.name === languageName)
    if (!voice) return
    updateSlide(
      {
        language: languageName,
        ...getDefaultVoice(voices, data.avatar.gender, languageName),
      },
      { ignoreHistory: true },
    )
  }

  /**
   * If user insert some text
   * call language detection
   * @param {SyntheticBaseEvent} event
   */
  const onPaste = async (event) => {
    event.preventDefault()
    const data = event.clipboardData.items
    for (let i = 0; i < data.length; i += 1) {
      if (data[i].kind !== 'string' || !data[i].type.match('^text/plain')) continue
      // eslint-disable-next-line no-loop-func
      data[i].getAsString(async (text) => {
        text = restoreTextFromJson(text)
        if (!text.length) return
        setIsSpeechEditorDisabled(true)
        await detectAndSetLanguage(text)
        setIsSpeechEditorDisabled(false)
        handleSpeechChanges(text)
      })
    }
  }

  // when state is changed - initiate speech update
  useEffect(() => {
    restoreCursorPosition()
    const timeoutId = setTimeout(saveSpeechChanges, 500)
    return () => clearTimeout(timeoutId)
  }, [speech])

  const insertPause = useCallback(
    (event) => {
      switch (event.key) {
        case '1': {
          handleSpeechChanges(PAUSE_EMOJI.small)
          break
        }
        case '2': {
          handleSpeechChanges(PAUSE_EMOJI.standard)
          break
        }
        case '3': {
          handleSpeechChanges(PAUSE_EMOJI.medium)
          break
        }
        case '4': {
          handleSpeechChanges(PAUSE_EMOJI.large)
          break
        }
        default: {
          return
        }
      }
      track('editor_pause_added')
    },
    [handleSpeechChanges],
  )

  const cutText = () => {
    const textarea = textareaRef.current
    if (!textarea) return

    const startPosition = textarea.selectionStart
    const endPosition = textarea.selectionEnd
    const currentValue = textarea.value
    const newValue = currentValue.substring(0, startPosition) + currentValue.substring(endPosition)

    handleSpeechChanges(newValue)
  }

  const copyText = async () => {
    const textarea = textareaRef.current

    if (textarea) {
      const selectedText = textarea.value.substring(textarea.selectionStart, textarea.selectionEnd)
      if (selectedText) {
        await navigator.clipboard.writeText(selectedText)
      }
    }
  }

  const checkSelectedPhonemes = (text, selectionStart, selectionEnd) => {
    const matches = [...text.matchAll(MULTIPLE_PHONEME_REGEX)]
    return matches.some((match) => {
      const tagStart = match.index
      const tagEnd = match.index + match[0].length
      return (
        (tagStart >= selectionStart && tagEnd <= selectionEnd) ||
        (selectionStart > tagStart && selectionStart < tagEnd) ||
        (selectionEnd > tagStart && selectionEnd < tagEnd)
      )
    })
  }

  const onClickPhoneme = useCallback(() => {
    if (isOpenPhonemeMenu) return setIsOpenPhonemeMenu(false)
    if (isOpenPhonemeInput) return handleClosePhonemeInput()
    const textarea = textareaRef.current
    if (textarea) {
      const selectionStart = textarea.selectionStart
      const selectionEnd = textarea.selectionEnd
      const isSelectedPhonemes = checkSelectedPhonemes(textarea.value, selectionStart, selectionEnd)
      if (selectionStart !== selectionEnd && !isSelectedPhonemes) {
        setIsOpenPhonemeMenu(true)
        textarea.focus()
        textarea.setSelectionRange(selectionStart, selectionEnd)
      } else {
        setIsOpenPhonemeModal(true)
      }
    }
  }, [isOpenPhonemeMenu, isOpenPhonemeInput])

  const insertMarker = useCallback(() => {
    handleSpeechChanges(TEMPORARY_MARKER_REPLACER)
    track('editor_mark_added')
  }, [handleSpeechChanges])

  const handleSpeechContextMenuClick = useCallback(
    async (e) => {
      switch (e.key) {
        case 'context_menu_cut':
          cutText()
          break
        case 'context_menu_copy':
          copyText()
          break
        case 'context_menu_paste': {
          let text = await navigator.clipboard.readText()
          setIsSpeechEditorDisabled(true)
          await detectAndSetLanguage(text)
          setIsSpeechEditorDisabled(false)
          handleSpeechChanges(text)
          break
        }
        case 'context_menu_phoneme':
          onClickPhoneme()
          break
        case '1':
        case '2':
        case '3':
        case '4': {
          insertPause(e)
          break
        }

        case 'context_menu_marker':
          insertMarker()
          break
        default:
          break
      }
      setIsOpenSpeechContextMenu(false)
    },
    [insertPause, onClickPhoneme, insertMarker],
  )

  const handleClickOutside = (event) => {
    if (speechContextMenuRef.current && !speechContextMenuRef.current.contains(event.target)) {
      setIsOpenSpeechContextMenu(false)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [speechContextMenuRef])

  const modifierKey = os === osMap.MAC ? '⌘' : 'Ctrl+'

  const isClipboardFullySupported = useMemo(
    () => !/(Safari|Firefox)\//i.test(navigator.userAgent) || navigator.userAgent.includes('Chrome'),
    [],
  )

  const speechContextMenuItems = useMemo(() => {
    const items = [
      { key: 'context_menu_phoneme', icon: <img src={PHONEME_ICON} className="contextMenuIcon" />, label: 'Phoneme' },
      { key: 'context_menu_marker', icon: <img src={MARKER_ICON} className="contextMenuIcon" />, label: 'Mark' },
      {
        key: 'content_menu_pause_main',
        icon: <img src={PAUSE_ICON} className="contextMenuIcon" />,
        label: 'Pause',
        children: [
          { key: '1', icon: PAUSE_EMOJI.small, label: '0.25s' },
          { key: '2', icon: PAUSE_EMOJI.standard, label: '0.5s' },
          { key: '3', icon: PAUSE_EMOJI.medium, label: '1s' },
          { key: '4', icon: PAUSE_EMOJI.large, label: '1.5s' },
        ],
      },
    ]
    if (isClipboardFullySupported)
      items.unshift(
        {
          key: 'context_menu_cut',
          icon: <Icon name="cut" />,
          label: (
            <span className="menu-item-label">
              <span>Cut</span> <Text type="secondary">{modifierKey}X</Text>
            </span>
          ),
        },
        {
          key: 'context_menu_copy',
          icon: <Icon name="copy" />,
          label: (
            <span className="menu-item-label">
              <span>Copy</span> <Text type="secondary">{modifierKey}C</Text>
            </span>
          ),
        },
        {
          key: 'context_menu_paste',
          icon: <Icon name="paste" />,
          label: (
            <span className="menu-item-label">
              <span>Paste</span>
              <Text type="secondary">{modifierKey}V</Text>
            </span>
          ),
        },
        { type: 'divider' },
      )

    return items
  }, [])

  const handleOpenContextMenu = () => setIsOpenSpeechContextMenu(true)

  const voice = useMemo(() => ({ id: data.voice, provider: data.voiceProvider }), [data.voice, data.voiceProvider])

  const handleTextareaKeyDown = (event) => {
    // block default undo and instead do history change
    if ((event.metaKey || event.ctrlKey) && event.code === 'KeyZ') {
      const textarea = textareaRef.current

      if (!isFocused) return
      if (history.length < 2) return
      history.pop()
      const prevSpeech = history[history.length - 1]
      const startPosition = findFirstDifferenceIndex(prevSpeech, speech)
      const diff = Math.max(prevSpeech.length - textarea.value.length, 0)
      setTextAreaCursorPosition({ startPosition, endPosition: startPosition + diff })

      undoLastChanges()
      event.preventDefault()
    }
  }

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  const isVoiceOver = data.canvas?.objects?.find((o) => o.type === 'avatar')?.avatarType === 'voiceover'

  const characterLimit = useMemo(() => {
    const allowLong = accountStatus === 'paid' && accountPlan !== 'basic'

    if (allowLong && (isVoiceOver || data?.avatar.limit > 60)) {
      return data.activeVoice.voiceProvider === 'azure' ? 7000 : 5000
    }
    return 1500
  }, [accountStatus, data.activeVoice, data?.avatar.limit, isVoiceOver])

  const handleClickPhonemeMenu = ({ key }) => {
    const textarea = textareaRef.current
    const selectionStart = textarea.selectionStart
    const selectionEnd = textarea.selectionEnd
    const selectedText = textarea.value.substring(textarea.selectionStart, textarea.selectionEnd)
    textarea.focus()
    textarea.setSelectionRange(selectionStart, selectionEnd)
    setIsOpenPhonemeMenu(false)
    if (key === 'dictionary') handleClickAddToDictionary(selectedText)
    else if (key === 'apply') handleClickApplyPhoneme(selectedText, textarea, selectionStart)
  }

  const handleClickAddToDictionary = (selectedText) => {
    if (SINGLE_PHONEME_REGEX.test(selectedText)) {
      const matches = [...selectedText.matchAll(MULTIPLE_PHONEME_REGEX)]
      const phoneme = matches[0][2]
      const word = matches[0][3]
      setWordForDictionary({ word, phoneme })
    } else {
      setWordForDictionary({ word: selectedText, phoneme: selectedText })
    }
    setIsOpenPhonemeModal(true)
  }

  const handleClickApplyPhoneme = (selectedText, textarea, selectionStart) => {
    setIsOpenPhonemeInput(true)
    const textToInsert = `<phoneme ph="${selectedText}">${selectedText}</phoneme>`
    handleSpeechChanges(textToInsert, false)
    setEditingPhonemeText(selectedText)
    setTimeout(() => {
      phonemeInputRef.current.focus()
      textarea.selectionStart = selectionStart + phonemeValuePosition
      textarea.selectionEnd = textarea.selectionStart + selectedText.length
    })
  }

  useClickOutside([phonemeButtonRef, phonemeMenuRef, phonemeInputWrapperRef, speechContextMenuRef], () => {
    if (isOpenPhonemeMenu) setIsOpenPhonemeMenu(false)
    if (isOpenPhonemeInput) handleClosePhonemeInput()
  })

  const savePhoneme = useCallback(
    (v) => {
      if (SPECIAL_CHARS_PHONEME_REGEX.test(v)) return
      handleSpeechChanges(v, false)
    },
    [handleSpeechChanges],
  )

  const debouncePhonemeChange = useCallback(
    debounce(500, (v) => savePhoneme(v)),
    [savePhoneme],
  )

  const onPhonemeChange = (e) => {
    setEditingPhonemeText(e.target.value)
    debouncePhonemeChange(e.target.value)
  }

  const handleClosePhonemeInput = () => {
    setIsOpenPhonemeInput(false)
    textareaRef.current.selectionEnd = textareaRef.current.selectionStart
    setEditingPhonemeText('')
  }

  const isMarkInsertAllowed = useMemo(() => marksTotal < MARK_EMOJI.length, [marksTotal])

  return {
    voice,
    speech,
    onPaste,
    handleBlur,
    handleFocus,
    textareaRef,
    insertPause,
    insertMarker,
    onClickPhoneme,
    onSpeechChange,
    characterLimit,
    wordForDictionary,
    isOpenPhonemeModal,
    setWordForDictionary,
    speechContextMenuRef,
    handleOpenContextMenu,
    handleTextareaKeyDown,
    setIsOpenPhonemeModal,
    speechContextMenuItems,
    isOpenSpeechContextMenu,
    handleSpeechContextMenuClick,
    isOpenPhonemeMenu,
    handleClickPhonemeMenu,
    isOpenPhonemeInput,
    phonemeButtonRef,
    phonemeMenuRef,
    phonemeInputRef,
    phonemeInputWrapperRef,
    onPhonemeChange,
    handleClosePhonemeInput,
    editingPhonemeText,
    isMarkInsertAllowed,
    isSpeechEditorDisabled,
  }
}
