import { useState, useEffect } from 'react'
import { Layout, Menu, Space, Button, Alert, Typography, Dropdown, Card, Tooltip } from 'antd'
import { hotjar } from 'react-hotjar'
import ReactGA from 'react-ga4'
import { Link, useLocation, useNavigate, Outlet, Navigate } from 'react-router-dom'
import axios from 'axios'
import Turnstile from 'react-turnstile'
import { useStore } from '../store'
import UpgradePlanModal from '../components/UpgradePlanModal/upgradePlanModal'
import Fonts from './fonts'
import { createSniperLink } from '../utils/account'
import { upgradeAccount } from '../utils/payment'
import PlanMinutesStatus from '../components/PlanMinutesStatus'
import Icon from '../components/Icon'
import { CreateVideoModal } from '../components/CreateVideoModal/index'
import Scrollbars from 'react-custom-scrollbars'
import WorkspacesMenu from '../components/Workspaces/workspacesMenu'
import { CATEGORIES } from '../components/CreateVideoModal/constants'
import './main.less'
import { AdminLoginByEmail } from '../components/AdminLoginByEmail'
import { adminDesigner } from './constants'
import { track } from '../utils/analytics'
import { useElaiNotification } from '../hooks/useElaiNotification'
import { MinutesNotification } from '../components/MinutesNotification'
import configService from '../utils/config'

const { Header, Content, Sider } = Layout

const encoder = new TextEncoder()

export default () => {
  const notification = useElaiNotification()
  const [isSiderOpen, setIsSiderOpen] = useState(false)
  const [documentWidth, setDocumentWidth] = useState(document.body.clientWidth)
  const [turnstileToken, setTurnstileToken] = useState()
  const [resendEmailAttempts, setResendEmailAttempts] = useState(0)
  const videosStore = useStore((stores) => stores.videosStore)
  const authStore = useStore((stores) => stores.authStore)
  const { isPaymentModalOpen, setIsPaymentModalOpen, header, setHeader, isNewVideoModalOpen, setIsNewVideoModalOpen } =
    useStore((stores) => stores.domStore)
  const location = useLocation()
  const navigate = useNavigate()

  const voicesStore = useStore((stores) => stores.voicesStore)

  const isMobileView = documentWidth < 560
  const canManageWorkspace = authStore?.user?.canManageWorkspace
  const { account } = authStore?.user ?? {}

  const { apiUrl } = configService.get().urls
  const { globalProd } = configService.get().env
  const { logoUrl, whiteLogoUrl } = configService.get().theme
  const { hideAcademy, signupLinksEnabled } = configService.get().features

  const resendVerificationCode = async (email) => {
    try {
      await axios.post(`${apiUrl}/auth/resend-code/${email}`, { turnstileToken })
      notification.success({ message: 'Verification email was successfully sent. Please check your inbox.' })
    } catch (error) {
      const message = error.response?.data?.message || error.message
      notification.error({ message })
    } finally {
      setTurnstileToken(false)
      setResendEmailAttempts((attempts) => attempts + 1)
    }
  }

  /**
   * Update Store when user logins
   */
  useEffect(() => {
    if (!authStore.user) return
    voicesStore.fetchVoices()
    videosStore.fetchTemplates()
    videosStore.fetchAvatars()
    authStore.refreshSession()

    ReactGA.set({ userId: authStore.user.id })

    if (globalProd) {
      // Init Hotjar only for priority leads
      if (account.additionalInfo?.priorityLead) {
        hotjar.initialize(2679889, 6)
        hotjar.identify(authStore.user.id)
      }

      // impact.com - create email hash and send when user authorizes
      if (window.ire) {
        crypto.subtle.digest('SHA-1', encoder.encode(authStore.user.email)).then((arrayBuffer) => {
          const hashArray = Array.from(new Uint8Array(arrayBuffer))
          const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
          window.ire('identify', { customerId: authStore.user.id, customerEmail: hashHex })
        })
      }
    }
  }, [authStore.user?.id])

  useEffect(() => {
    if (location.hash.includes('subscription') && !isPaymentModalOpen && canManageWorkspace) {
      setIsPaymentModalOpen(true)
    } else if (
      [CATEGORIES.regular, CATEGORIES.story, CATEGORIES.pdf].includes(window.location.hash.replace('#', '')) &&
      ['/', '/videos', '/videos/'].includes(location.pathname) &&
      !isNewVideoModalOpen
    ) {
      setIsNewVideoModalOpen(true)
    }
  }, [location.hash])

  const { avatars } = videosStore

  useEffect(() => {
    if (!account) return

    if (location.pathname.includes('/admin/'))
      setHeader({
        title: 'Home',
        icon: <Icon name="house" />,
        link: '/',
        extra: null,
      })
    /**
     * set document width to render header menu for mobile or desktop views
     */
    const handleWindowResize = () => setDocumentWidth(document.body.clientWidth)
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [account])

  useEffect(() => {
    if (!isMobileView)
      setIsSiderOpen(
        !['/admin/', '/video/', '/preview/', '/avatar/', '/story/', '/academy'].some((path) =>
          location.pathname.includes(path),
        ),
      )
    if (
      authStore.user?.accountRole === 'locked' &&
      !['/video/', '/preview/', '/story/'].some((path) => location.pathname.includes(path))
    ) {
      notification.error({ message: "You're not allowed to access this page", duration: false })
      navigate('404')
    }
  }, [location.pathname])

  if (!authStore.user) {
    // if user is not logged in and trying to access main page
    if (location.pathname === '/' && !localStorage.getItem('token'))
      return <Navigate to={signupLinksEnabled ? 'signup' : 'login'} state={{ from: location }} />

    return localStorage.getItem('token') ? null : <Navigate to="/login" />
  }

  const onClickLogout = () => {
    authStore.logout({ afterAdminLogout: () => window.location.reload() })
    track('log_out')
  }

  const avatarId = avatars?.find((avatar) => avatar.accountId === account.id && avatar.status === 4)?._id
  const accountHasVoice = account.voices?.some((voice) => voice.status === 1)
  const avatarWithVoiceId = avatars?.find((avatar) => avatar.accountId === account.id)?._id
  const avatarAccountId = avatarId || (accountHasVoice ? avatarWithVoiceId : false)

  const onLinkClick = () => {
    localStorage.setItem('page', 1)
  }

  // TODO: map maybe?
  const siderMenuItems = [
    {
      label: (
        <Link to="/" onClick={onLinkClick}>
          Home
        </Link>
      ),
      key: '/',
      icon: <Icon name="house" />,
    },
    {
      label: <Link to="/videos">Videos</Link>,
      key: '/videos',
      icon: <Icon name="video_camera" />,
    },
    {
      label: (
        <Link to="/templates" onClick={onLinkClick}>
          Templates
        </Link>
      ),
      key: '/templates',
      icon: <Icon name="multiple_image" />,
    },
    {
      label: (
        <Link to={avatarAccountId ? `/avatar/${avatarAccountId}` : '/buy-avatar'} target="_blank" onClick={onLinkClick}>
          {avatarAccountId ? 'Add avatar data' : 'Create avatar'}
        </Link>
      ),
      key: '/buy-avatar',
      icon: <Icon name="create_avatar" />,
    },
    {
      label: (
        <Link to="avatars" onClick={onLinkClick}>
          Avatars library
        </Link>
      ),
      key: '/avatars',
      icon: <Icon name="users" />,
    },
    !hideAcademy && {
      label: (
        <Link to="academy" target="_blank" onClick={onLinkClick}>
          Elai Academy
        </Link>
      ),
      key: '/academy',
      icon: <Icon name="mortarboard" />,
    },
    {
      label: (
        <Link to="/deleted-videos" onClick={onLinkClick}>
          Deleted videos
        </Link>
      ),
      key: '/deleted-videos',
      icon: <Icon name="delete" />,
    },
  ]

  const siderLowerMenuItems = [
    {
      label: (
        <a
          target="_blank"
          href="https://elai.freshdesk.com/support/home"
          rel="noreferrer"
          onClick={() => {
            track('support_request')
            onLinkClick()
          }}
        >
          <Icon name="support" />
          <span>Support</span>
        </a>
      ),
      key: 'support',
    },
    {
      label: (
        <a
          target="_blank"
          href="https://feedback.elai.io/roadmap"
          rel="noreferrer"
          onClick={() => {
            track('header_feedbear_click')
            onLinkClick()
          }}
        >
          <Icon name="discuss" />
          <span>Discuss</span>
        </a>
      ),
      key: 'discuss',
    },
  ]

  const userRole = authStore.user.role

  const adminMenuItems = [
    ['admin', 'developer', 'avatarsAdmin'].includes(authStore.user.role) && {
      link: '/admin/videos',
      icon: 'video_camera',
      name: 'Videos',
    },
    authStore.user.role === 'admin' && {
      link: '/admin/users',
      icon: 'team',
      name: 'Users',
    },
    adminDesigner.includes(userRole) && {
      link: '/admin/templates',
      icon: 'multiple_image',
      name: 'Templates',
    },
    ['admin', 'avatarsAdmin'].includes(authStore.user.role) && {
      link: '/admin/avatars',
      icon: 'user',
      name: 'Avatars',
    },
    ['admin', 'avatarsAdmin'].includes(authStore.user.role) && {
      link: '/admin/voices',
      icon: 'volume',
      name: 'Voices',
    },
    adminDesigner.includes(userRole) && {
      link: '/admin/courses',
      icon: 'mortarboard',
      name: 'Academy',
    },
  ]

  const profileMenuItems = [
    {
      label: (
        <div>
          <p>{authStore.user.name}</p>
          <Typography.Text type="secondary">{authStore.user.email}</Typography.Text>
        </div>
      ),
      key: 'name',
    },
    { label: <Link to="/profile">My profile</Link>, key: '/profile', icon: <Icon name="personal" /> },
    { label: <Link to="/workspace">Workspace settings</Link>, key: '/workspace', icon: <Icon name="team" /> },
    account.plan !== 'unlimited' && {
      label: <Link to="/api">API Settings</Link>,
      key: '/api',
      icon: <Icon name="setting" />,
    },
    {
      label: ['custom', 'unlimited'].includes(account.plan) ? (
        <Link to="/setup-sso">Single Sign-on</Link>
      ) : (
        <Tooltip title="SSO is available only in Enterprise plans">Single Sign-on</Tooltip>
      ),
      key: '/setup-sso',
      icon: <Icon name="connection" />,
      disabled: !['custom', 'unlimited'].includes(account.plan),
    },
    {
      label: 'Logout',
      key: 'logout',
      icon: <Icon name="logout" />,
      onClick: onClickLogout,
    },
  ]

  if (isMobileView && authStore.user.isAdmin)
    profileMenuItems.push({
      label: 'Admin',
      key: '/admin',
      icon: <Icon name="repair_tool" />,
      children: adminMenuItems.map(
        (i) =>
          i && {
            label: (
              <Link to={i.link}>
                <Icon name={i.icon} /> {i.name}
              </Link>
            ),
            key: i.link,
          },
      ),
    })

  return (
    <Layout
      className="home"
      style={
        location.pathname.includes('/video/') || location.pathname.includes('/admin/') ? { minWidth: '1310px' } : {}
      }
    >
      <Alert
        className="header-alert-mobile"
        icon={<Icon name="desktop_cursor_filled" />}
        message="For a seamless editing experience, please switch to your desktop device."
        showIcon
        closable
      />
      {!location.pathname.includes('/video/') &&
        !location.pathname.includes('/story/') &&
        !location.pathname.includes('/academy') && (
          <Header className="mobile">
            <div>
              <Button type="link" className="btn-menu" onClick={() => setIsSiderOpen(!isSiderOpen)}>
                <Icon name="menu" />
              </Button>
              <Link to="/">
                <img className="logo" src={whiteLogoUrl || logoUrl} alt="Elai Logo" />
              </Link>
            </div>
            {authStore.user.accountRole !== 'locked' && (
              <Dropdown
                menu={{
                  items: profileMenuItems,
                  className: 'profile-dropdown-menu',
                  onClick: () => setIsSiderOpen(false),
                }}
                trigger={['click']}
              >
                <div className="profile-dropdown-title">
                  {authStore.user.name}
                  <>
                    <Icon name="down_arrow" className="down_icon" />
                    <Icon name="up_arrow" className="up_icon" />
                  </>
                </div>
              </Dropdown>
            )}
          </Header>
        )}
      <Sider className={`sider ${isSiderOpen ? 'open' : ''}`} width={240}>
        <Scrollbars className="scrollbar">
          <div className="scrollbar-content">
            <div>
              <Link to="/" className="desktop">
                <img className="logo" src={whiteLogoUrl || logoUrl} alt="Elai Logo" />
              </Link>
              <WorkspacesMenu />
              <Menu
                className="sider-menu"
                theme="dark"
                selectedKeys={[`/${location.pathname.split('/')[1]}`]}
                mode="inline"
                items={siderMenuItems}
                onClick={() => isMobileView && setIsSiderOpen(false)}
              />
            </div>
            <Card className="minutes-status-card">
              {account.plan === 'unlimited' ? (
                <Typography.Text type="secondary">Need more seats? Upgrade your plan.</Typography.Text>
              ) : (
                <>
                  <PlanMinutesStatus />
                  <Typography.Text type="secondary">
                    {account.status === 'paid'
                      ? 'Need more minutes? Upgrade your plan.'
                      : 'Upgrade to paid plan. Cancel anytime.'}
                  </Typography.Text>
                </>
              )}
              <Tooltip
                title="Please contact your account owner to upgrade."
                overlayStyle={{
                  visibility: canManageWorkspace ? 'hidden' : 'visible',
                }}
              >
                <Button
                  type="primary"
                  disabled={!canManageWorkspace}
                  onClick={() => {
                    onLinkClick()
                    upgradeAccount(account)
                  }}
                >
                  Upgrade
                </Button>
              </Tooltip>
              <MinutesNotification />
            </Card>
            <div>
              <Menu className="sider-menu" theme="dark" selectable={false} mode="inline" items={siderLowerMenuItems} />
            </div>
          </div>
        </Scrollbars>
      </Sider>
      {(!isMobileView || !isSiderOpen) && (
        <Layout className={`main-content ${location.pathname.includes('/academy') ? 'academy' : null}`}>
          {!location.pathname.includes('/video/') &&
            !location.pathname.includes('/story/') &&
            !location.pathname.includes('/academy') && (
              <>
                <Header className="desktop">
                  <Link to={authStore.user.accountRole !== 'locked' ? header.link : false} className="header-title">
                    {header.icon}
                    {header.title}
                  </Link>
                  {authStore.user.isAdmin && (
                    <div className="header-admin">
                      {authStore.user.adminAccountId && <h4 className="admin-logined">{authStore.user.email}</h4>}
                      <Menu
                        style={{ minWidth: 0, flex: 'auto' }}
                        selectedKeys={[location.pathname]}
                        mode="horizontal"
                        items={adminMenuItems.map(
                          (i) =>
                            i && {
                              label: (
                                <Link to={i.link}>
                                  <Icon name={i.icon} /> {i.name}
                                </Link>
                              ),
                              key: i.link,
                            },
                        )}
                      />
                      <AdminLoginByEmail />
                    </div>
                  )}
                  <Space size="large">
                    {header.extra}
                    {authStore.user.accountRole !== 'locked' && (
                      <Dropdown
                        menu={{ items: profileMenuItems, className: 'profile-dropdown-menu' }}
                        trigger={['click']}
                      >
                        <div className="profile-dropdown-title">
                          {authStore.user.name}
                          <>
                            <Icon name="down_arrow" className="down_icon" />
                            <Icon name="up_arrow" className="up_icon" />
                          </>
                        </div>
                      </Dropdown>
                    )}
                  </Space>
                </Header>
              </>
            )}
          <Content className="main-content-container">
            <Scrollbars className="main-content-scrollbar scrollbar">
              <div>
                {authStore.user.status === 'register' && (
                  <Alert
                    className="header-alert"
                    message={
                      <div>
                        <p>Please verify your email address {authStore.user.email} </p>
                        <a href={createSniperLink(authStore.user.email)} target="_blank" rel="noreferrer">
                          Check inbox
                        </a>
                        <Tooltip
                          title="We are applying captcha. Please wait a second…"
                          overlayStyle={{
                            visibility: turnstileToken ? 'hidden' : 'visible',
                          }}
                        >
                          <a
                            className={turnstileToken ? '' : 'disabled'}
                            onClick={() => (turnstileToken ? resendVerificationCode(authStore.user.email) : null)}
                          >
                            Resend email
                          </a>
                        </Tooltip>
                        <div key={resendEmailAttempts} style={{ minWidth: 40 }}>
                          <Turnstile
                            sitekey={process.env.REACT_APP_TURNSTILE_SITE_KEY}
                            autoResetOnExpire={true}
                            action="resend-email"
                            className="turnstile-captcha"
                            appearance="interaction-only"
                            onVerify={(token) => setTurnstileToken(token)}
                            onError={() => setResendEmailAttempts((attempts) => attempts + 1)}
                          />
                        </div>
                      </div>
                    }
                    type="warning"
                  />
                )}
                {['/', '/videos', '/templates', '/workspace'].includes(location.pathname) && (
                  <div className="subheader-btn-container mobile">
                    <Link to={header.link} className="header-title">
                      {header.icon}
                      {header.title}
                    </Link>
                    <div>{header.extra}</div>
                  </div>
                )}
                <Outlet />
              </div>
            </Scrollbars>
          </Content>
        </Layout>
      )}
      <CreateVideoModal isNewVideoModalOpen={isNewVideoModalOpen} setIsNewVideoModalOpen={setIsNewVideoModalOpen} />
      <UpgradePlanModal isOpen={isPaymentModalOpen} setIsOpen={setIsPaymentModalOpen} />
      <Fonts />
    </Layout>
  )
}
