const findFirstUnavailableForLayeringIndex = (objects) => {
  return objects.findIndex((obj) => !isCanvasObjectLayeringAvailable(obj))
}

export const isCanvasObjectLayeringAvailable = (canvasObject) => {
  if (!canvasObject) {
    return false
  }

  if (canvasObject.meta?.interactivity || canvasObject.type === 'question') {
    return false
  }

  if (['group', 'activeSelection'].includes(canvasObject.type)) {
    return canvasObject.getObjects().every((obj) => isCanvasObjectLayeringAvailable(obj))
  }

  return true
}

/**
 * Handles the layering of canvas objects based on the specific conditions.
 *
 * @param {Object} params - The parameters.
 * @param {Object} params.canvas - The canvas instance.
 * @param {string} params.action - The action to perform ('sendToBack', 'sendBackwards', 'bringForward', 'bringToFront').
 * @param {Object} params.canvasObject - The canvas object to layer.
 */
export const canvasLayering = ({ canvas, action, canvasObject }) => {
  const isLayeringAvailable = isCanvasObjectLayeringAvailable(canvasObject)
  if (!isLayeringAvailable) {
    return
  }

  if (['sendToBack', 'sendBackwards'].includes(action)) {
    canvas[action](canvasObject)
    return
  }

  const objects = canvas.getObjects()
  const firstUnavailableForLayeringIndex = findFirstUnavailableForLayeringIndex(objects)

  if ('bringForward' === action) {
    const objectIndex = objects.findIndex((obj) => obj === canvasObject)

    if (firstUnavailableForLayeringIndex === -1 || objectIndex < firstUnavailableForLayeringIndex - 1) {
      canvas[action](canvasObject)
    }

    return
  }

  if ('bringToFront' === action) {
    if (firstUnavailableForLayeringIndex > 0) {
      canvas.moveTo(canvasObject, firstUnavailableForLayeringIndex - 1)
    } else if (firstUnavailableForLayeringIndex === -1) {
      canvas[action](canvasObject)
    }

    return
  }
}

export const adjustCanvasObjectLayering = ({ canvas, canvasObject }) => {
  if (!canvasObject || !canvas) {
    return
  }

  const isLayeringAvailable = isCanvasObjectLayeringAvailable(canvasObject)

  const objects = canvas.getObjects()

  if (!isLayeringAvailable) {
    canvas.bringToFront(canvasObject)
    return
  }

  const firstUnavailableForLayeringIndex = findFirstUnavailableForLayeringIndex(objects)
  const objectIndex = objects.findIndex((obj) => obj === canvasObject)

  if (firstUnavailableForLayeringIndex !== -1 && objectIndex > firstUnavailableForLayeringIndex) {
    canvas.moveTo(canvasObject, firstUnavailableForLayeringIndex)
  }
}
